<template>
<a-form :form="form" @submit="handleSubmit" class="p-form">
  <div class="register">
  	<div class="box-left">
  		<div class="logo"><img :src="logo" style=""/></div>
  		<div class="title">
  			BEGIN YOUR <br/>CO-CREATION <br/>JOURNEY
  		</div>
  		<div class="desc">
  			<!--Create an account through<br/>which you can submit your<br/>tenancy application-->
  		</div>
  	</div>
  	<div class="form-box">
  		<div class="block-nav">
  			<a href="javascript:;" @click="$router.push({path: '/'});">Back to Home</a>
  			<a href="javascript:;" @click="$router.push({path: '/'});">繁</a>
  			<a href="javascript:;" @click="$router.push({path: '/'});">简</a>
  		</div>
  		<h3 class="form-title">Create Your Account</h3>
  		<div class="form">
              <a-alert
                  v-if="isError"
                  :message="errorMessage"
                  type="error"
                  banner
                  closable
                />
              <a-form-item label="Full Name in English (Mr/Mrs/Miss)">
                <a-input
                  v-decorator="['name', { rules: [{ required: true, message: 'Please input your full name!' }] }]"
                />
              </a-form-item>
              <a-form-item label="Company Email">
                <a-input
                  v-decorator="['email', { rules: [{type: 'email',message:'The input is not valid E-mail!'},{ required: true, message: 'Please input your Email!' }] }]"
                />
              </a-form-item>
    			    <a-form-item label="Password">
                <a-input
                  v-decorator="['password', { rules: [{ required: true, message: 'Please input your Password!' }] }]"
                  type="password"
                />
              </a-form-item>
              <a-form-item label="Confirm Password">
                <a-input
                  v-decorator="['confirm_password', { rules: [{'required':true,message: 'Please input your confirm password!'},{validator: compareToFirstPassword}] }]"
                  type="password"
                />
              </a-form-item>
             
              
              <a-form-item label="Company Name">
                  <a-select
                    v-decorator="[
                        'companyName',
                        { rules: [{ required: true, message: 'Please select your Company!' }] },
                      ]"
                    show-search
                    placeholder="Select a Company"
                    @change="handleChange"
                  >
                    <a-select-option :value="item.companyName" v-for="(item,index) in companys">
                      {{item.companyName}}
                    </a-select-option>
                    
                  </a-select>
                </a-form-item>
              <a-form-item label="Position">
                <a-input
                  v-decorator="['position', { rules: [{'required':true,message: 'Please input your position'}] }]"
                />
              </a-form-item>
              <a-form-item label="Contact No">
                <a-input
                  v-decorator="['contactNo', { rules: [{'required':true,message: 'Please input your contact no.'}] }]"
                />
              </a-form-item>
              <a-form-item label="Local / Mainland & Oversea">
              <a-select
                    v-decorator="[
                        'isLocal',
                        { rules: [{ required: true, message: 'Please select Local / Mainland & Oversea' }] },
                      ]"
                    placeholder="Select Local / Mainland or Oversea"
                  >
                    <a-select-option value="0" >
                     Local
                    </a-select-option>
                    <a-select-option value="1" >
                     Mainland
                    </a-select-option>
                    <a-select-option value="2" >
                      Oversea
                    </a-select-option>
                  </a-select>
              </a-form-item>
              <div class="form-group" style="text-align:left;display: table">
                <span style="display: table-cell;vertical-align: middle;">
                  <a-checkbox @change="changePolicy" v-decorator="['isPolicy',{ rules: policyrules}]">
                    
                  </a-checkbox>
                </span>
                <span class="m-notice" style="display: table-cell;padding: 0 15px;line-height: 12px;">I have read and accepted the <a href="http://hkstp.org/term-of-use/" target="_blank">Terms of Service</a><br/> and <a target="_blank" href="http://hkstp.org/privacy-policy/">Privacy Policy</a></span>
              </div>
              <div class="form-group" style="margin-top:40px;">
                  <button class="btn btn-info btn-block" :disabled="btnDisabled" type="submit"><a-spin :indicator="indicator" v-if="btnLoad"/> Sign Up</button>
              </div>
              <div class="form-group">
              	<p class="m-notice">Already have an account? <a href="javascript:;" @click="$router.push({path: '/login'});">Log In</a></p>
              </div>
    		</div>
      
  	</div>
  </div>
  <div v-if="isLoad">
    <a-spin />
  </div>
   <div class="manager-view" v-if="showviewHtml" style="background-color:#fff;">
   <h3>Thank you! </h3>
      We will send you the login details by email shortly.
      <br/>
      <p style="text-align:center;"><br/><br/><a class="btn btn-primary" @click="loginCloseView">OK</a></p>
  </div>
  </a-form>
</template>
<script>

import global from '../global'
import jq from 'jquery'
export default {
    name: 'Register',
    data() {
        return {
           logo:require('../assets/img/Logos@2.png'),
           form: this.$form.createForm(this, { name: 'coordinated' }),
           isLoad:false,
           errorMessage:'',
           isError:false,
           companys:[],
           policyrules:[{'required':true,message: 'Please input your confirm password!'}],
           btnDisabled:true,
           showviewHtml:false,
           makeHtml:null,
           idCom:'',
          btnLoad:false,
          indicator:<a-icon type="loading" style="font-size: 16px" spin />,
        }
    },
    mounted() {
        if(this.$cookies.isKey('_TOKEN_') && this.$cookies.get('_TOKEN_') != null ){
             window.location.href = "/#/welcome"
        }
        this.companys = [];
        this._initCompanys();
    },
     methods: {
        
        handleSubmit(e){
            let that = this;
            e.preventDefault();
            that.isError = false;
            this.form.validateFields((err, values) => {

              if (!err) {
                  that.isLoad = true;
                  that.btnDisabled = that.btnLoad = true;
                  values.idCom = this.idCom
                  jq.post(global.api.domain+"forntend/register.do",values,function(response){
                       that.isLoad = false;
                       that.btnDisabled = that.btnLoad = false;
                      if(response.code == 0){
                          //that.$cookies.set('_USER_',response.data);
                          //that.$cookies.set('_TOKEN_',response.data.token);
                           if(that.makeHtml == null || that.makeHtml == ''){
                              that.makeHtml = document.createElement("div");
                              that.makeHtml.classList.add("dialog-make");
                              document.body.appendChild(that.makeHtml);
                            }
                            that.showviewHtml = true;
                      }else{
                        if(response.msg != null){
                            that.isError = true;
                            that.errorMessage = 'You already have an account, please go back to the login page';
                        }
                          
                      }
                      console.log(response.data)
                  })
                  console.log('Received values of form: ', values);
              }
            });
        },
        changePolicy(e){
            this.btnDisabled = true; 
            if(e.target.checked){
               this.btnDisabled = false; 
            }

        },
        loginCloseView(){
          this.showviewHtml = false;
          this.makeHtml.remove();
          location.href="#/login"
        },
        _initCompanys(){
          var that = this;
          that.isLoad = true;
          this.axios
              .get(global.api.domain+"forntend/listAllCompany.do",{},{headers:global.api.headers})
              .then((response) => {
                  that.isLoad = false;
                  if(response.data.code == 0){
                    that.companys = response.data.data;
                  }
                  //that.companys = response.data;
                  console.log(response.data.data)
            })
        },
        compareToFirstPassword(rule, value, callback){
           const form = this.form;
           if (value && value !== form.getFieldValue('password')) {
              callback('Two passwords that you enter is inconsistent!');
            } else {
              callback();
            }
        },
        handleChange(value){
          var that = this;
          this.companys.forEach(function(v){
              if(value == v.companyName){
                that.idCom = v.idCom;
              }
          })
          console.log(value+"---------")
        }
     }
}
</script>
<style>
	body{
		background: #fff;
	}
	#app{
		height:99.8%;
		background: #F1F1F1;
	}
	.main-box{
		height:100%;
		
	}
	.content{
			height:100%;
		}
@media screen and (max-width:1079px){
	#app{
		background: #F1F1F1;

	}
}
</style>